import React from 'react'

import { Row, Col } from 'react-grid-system'

import ContactInfo from '@components/ContactInfo'
import Lead from '@components/Lead'
import PaddedBlock from '@components/PaddedBlock'
import SEO from '@components/SEO'

import styles from './reach-us.module.css'

const ReachPage = () => (
  <React.Fragment>
    <SEO title="Reach us" />

    <PaddedBlock>
      <Lead>We are located at an accessible dock on the shore of the Nile</Lead>
      <address className={styles.address}>
        <Row>
          <Col sm={6}>
            <div className={styles.item}>
              <ContactInfo single type="address" />
            </div>
            <div className={styles.item}>
              <ContactInfo single type="gps" />
            </div>
          </Col>
          <Col sm={6}>
            <div className={styles.item}>
              <ContactInfo single type="phone" />
            </div>
            <div className={styles.item}>
              <ContactInfo single type="email" />
            </div>
            <div className={styles.item}>
              <ContactInfo single type="social" />
            </div>
          </Col>
        </Row>
      </address>
    </PaddedBlock>
  </React.Fragment>
)

export default ReachPage
